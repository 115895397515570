import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    user: null,
}

const slice: any = createSlice({
    name: "common.slice",
    initialState,
    reducers: {
        setUser(state, { payload }) {
            state.user = payload
        },
        clearUser(state) {
            state.user = null
        }
    }
})

export const { setUser, clearUser } = slice.actions;
export default slice.reducer;