import { useDisclosure } from "@chakra-ui/react"
import { ACTIVITY, STUDENT } from "../../../consts/methods.consts"
import { ACTIVITY_TAGS } from "../../../consts/tags.consts"
import { useGetQuery } from "../../../services/api.service"
import { useTableDataProvider } from "../../hooks"
import AllocateResourceModal from "../allocate-resource-modal/AllocateResourceModal"
import ChakraTable, { ChakraTableColumns } from "../chakra-table/ChakraTable"
import { SectionCardCreatable } from "../section-card/SectionCard"
import { useState } from "react"
import DeallocateResourceModal from "../deallocate-resource-modal/DeallocateResourceModal"

interface IResourceTab {
    id: string | undefined,
    type: "student" | "staff"
}

const methodMap: any = {
    "student": STUDENT.RESOURCE_LIST,
    "staff": ACTIVITY.LIST_BY_STAFF
}

const LIST_TAB_COLUMNS: ChakraTableColumns = [
    {
        accessor: "resource_id",
        header: "Resource ID"
    },
    {
        accessor: "resource_title",
        header: "Title"
    },
    {
        accessor: "resource_type",
        header: "Type",
    },
    {
        accessor: "sign_out_time",
        header: "Allocated Date"
    },
    {
        accessor: "due_date",
        header: "Due Date",
    },
    {
        accessor: "sign_in_time",
        header: "Handover Date",
    }
]

const ResourceTab = ({ id, type }: IResourceTab) => {
    const [resource, setResource] = useState(null)
    const _disclosure = useDisclosure();
    const _handoverDisclosure = useDisclosure();

    const { data, isLoading } = useGetQuery({
        method: methodMap[type],
        body: { id },
        providesTags: [ACTIVITY_TAGS.LIST]
    })

    const { pageCount, dataList } = useTableDataProvider(data)

    const creatable: SectionCardCreatable = {
        text: "Allocate",
        onClick: () => {
            _disclosure.onOpen();
        }
    }

    const _onSelected = (line: any) => {
        setResource(line)
        _handoverDisclosure.onOpen()
    }

    return (
        <>
            <AllocateResourceModal id={id} title={"Allocate Resource"} type={"student"} disclosure={_disclosure} />
            <DeallocateResourceModal title={"Handover Resource"} data={resource} disclosure={_handoverDisclosure} />
            <ChakraTable
                creatable={creatable}
                header
                title={"Resource Allocation"}
                size={"sm"}
                onSelected={_onSelected}
                columns={LIST_TAB_COLUMNS}
                data={dataList}
                pageCount={pageCount}
                pagination
            />
        </>
    )
}

export default ResourceTab