import { useLocation } from "react-router-dom"

export const usePathMetaProvider = (): string => {
    const location = useLocation();
    const path = location.pathname;

    if (path == "/") {
        return "home";
    }

    if (path == "/app") {
        return "home";
    }

    const pathArr = location.pathname.split("/", 3)

    return pathArr[2];
}