import {PRODUCT} from "../../../../consts/methods.consts"
import {PRODUCT_TAG} from "../../../../consts/tags.consts"
import {useGetQuery} from "../../../../services/api.service"
import {StatCard} from "../../../common/stat/StatCard"
import {NavigateFunction, useNavigate} from "react-router-dom";

const CompleteProductStatCard = () => {
    const navigator: NavigateFunction = useNavigate()
    const {data} = useGetQuery({
        method: PRODUCT.COMPLETE_COUNT,
        body: {},
        providesTags: [PRODUCT_TAG.LIST]
    })

    return (
        <div onClick={() => navigator("completed-list")}>
            <StatCard key={'complete_count'} label='Completed' value={String(data?.message ?? 0)}
                      className={"bg-gradient-to-bl from-green-100 to-green-200 cursor-pointer"}/>
        </div>
    )
}

export default CompleteProductStatCard

// delta={{ value: '20%', isUpwardsTrend: true }}
