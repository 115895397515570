import * as Yup from "yup";
import {useState} from 'react';
import {FormInput} from '../../../common/form-controllers/FormInput';
import {useFormik} from 'formik';
import {ToastService} from '../../../../services/toast.service';
import {useLoginMutation} from '../../../../services/api.service';
import {NavigateFunction, useNavigate} from 'react-router-dom';
import {
    Box,
    Button,
    Checkbox,
    Flex,
    Heading,
    HStack,
    Stack,
    StackProps,
    useBreakpointValue,
    useToast
} from '@chakra-ui/react';
import SelectSchool from "./SelectSchool";

export const SignInForm = (props: StackProps) => {
    const navigator: NavigateFunction = useNavigate()
    const toast = new ToastService(useToast())

    const isMobile = useBreakpointValue<boolean>({base: true, md: false})
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [login] = useLoginMutation();
    const [multipleSchools, setMultipleSchool] = useState(false)
    const headerBreakpoint = useBreakpointValue({base: 'xs', md: 'sm'})

    const formik = useFormik({
        initialValues: {
            usr: "",
            pwd: "",
            device: "mobile"
        },
        validationSchema: Yup.object({
            usr: Yup.string().required("Email is required"),
            pwd: Yup.string().required("Password is required")
        }),
        onSubmit: async (values: any) => {
            try {
                setIsLoading(true);
                let data = await login(values).unwrap();
                if (typeof data == 'object' && data.hasOwnProperty("multiple_school")) {
                    setMultipleSchool(data["multiple_school"])
                } else {
                    navigator("/app");
                }

            } catch (error) {
                toast.setTitle("Error").setDescription("Invalid credentials. Try again").showErrorToast()
                console.error(error)
            } finally {
                setIsLoading(false)
            }
        }
    })

    if (multipleSchools) {
        return <SelectSchool/>
    }


    return (
        <Box h={'100vh'} className='flex flex-col justify-center' {...props}>
            <div className='h-fit bg-gray-50 border rounded shadow-sm p-6'>
                <Stack spacing="6">
                    {isMobile && (
                        <Flex align="center" justifyContent={"center"}>
                            <HStack spacing={0} alignItems={"center"}>
                                <Heading size={"lg"} fontWeight={"medium"}>[LOGO]</Heading>
                                <Heading size={"lg"} fontWeight={"light"}>EDPASS</Heading>
                            </HStack>
                        </Flex>
                    )}
                    {isMobile && "da"}
                    <Stack spacing={{base: '2', md: '3'}} textAlign="center">
                        <Heading size={headerBreakpoint}>
                            Log in to your account
                        </Heading>
                    </Stack>
                </Stack>
                <form onSubmit={formik.handleSubmit}>
                    <Stack spacing="6">
                        <Stack spacing="2">
                            <FormInput isRequired formik={formik} name='usr' label='Email'/>
                            <FormInput type='password' isRequired formik={formik} name='pwd' label='Password'/>
                        </Stack>
                        <HStack justify="space-between">
                            <Checkbox defaultChecked>Remember me</Checkbox>
                            {/* <Button variant="link" colorScheme="blue" size="sm">
                                Forgot password
                            </Button> */}
                        </HStack>
                        <Stack spacing="4">
                            <Button isLoading={isLoading} type={'submit'}
                                    className='!bg-gray-700 !text-white hover:!bg-gray-900 !transition-all'>Sign
                                in</Button>
                        </Stack>
                    </Stack>
                </form>
            </div>
        </Box>
    )
}
