import { SCHOOL } from "../../../../consts/methods.consts";
import { useToast } from "@chakra-ui/react";
import { SCHOOL_TAGS } from "../../../../consts/tags.consts";
import { useSelector } from "react-redux";
import { ToastService } from "../../../../services/toast.service";
import { useEffect, useRef, useState } from "react";
import { useGetQuery, useLogoutMutation, usePostMutation } from "../../../../services/api.service";
import { NavigateFunction, useNavigate } from "react-router-dom";

const userSelector = (state: any) => state?.common?.user
const Menu = () => {
    const ref: any = useRef(null)
    const user = useSelector(userSelector)
    const navigate: NavigateFunction = useNavigate();
    const toast = new ToastService(useToast())
    const [logout] = useLogoutMutation();
    const [selectSchool] = usePostMutation()
    const [isVisibleMenu, setMenuVisible] = useState(false)

    const { data } = useGetQuery({
        method: SCHOOL.GET_SCHOOL_LIST,
        body: {},
        providesTags: [SCHOOL_TAGS.LIST]
    })

    const { data: selectedSCLData } = useGetQuery({
        method: SCHOOL.GET_SELECTED_SCHOOL,
        body: {},
        providesTags: [SCHOOL_TAGS.LIST]
    })

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const selectSchoolHandler = (school: string) => async () => {
        try {
            await selectSchool({
                method: SCHOOL.SELECT_SCHOOL,
                body: { id: school },
                invalidatesTags: []
            }).unwrap()
            window.location.reload()
        } catch (error) {
            toast.setTitle("Error").setDescription("Try again").showErrorToast()
        }
    }

    const logoutHandler = async () => {
        try {
            await logout({}).unwrap();

            navigate("/", { replace: true })
            window.location.reload()

        } catch (error) {
            console.error(error);
            toast.setTitle("Error").setDescription("Unable to logout. Something went wrong").showErrorToast();
        }
    }
    const handleClickOutside = (event: any) => {
        if (ref.current && !ref.current.contains(event.target)) {
            setMenuVisible(false);
        }
    };

    const getSelectedSchool = (schools: [], schoolId: string) => {
        if (Array.isArray(schools) && schoolId) {
            let school: any = schools.find((line: any) => line?.name === schoolId)
            return school?.title
        }

        return ""
    }

    const menuMarkup = isVisibleMenu && (
        <div ref={ref}
            className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none divide-y divide-gray-100">
            <div className="py-1" role="none">
                <button className="text-gray-700 block w-full px-4 py-2 text-left text-sm hover:bg-gray-100"
                    type="submit" role="menuitem" id="menu-item-3">
                    {user}
                </button>
                <button onClick={logoutHandler}
                    className="text-gray-700 block w-full px-4 py-2 text-left text-sm hover:bg-gray-100"
                    type="submit" role="menuitem" id="menu-item-3">
                    Sign out
                </button>
            </div>
        </div>
    )

    return (
        <>
            <div tabIndex={0}
                className="relative inline-block text-left p-1 rounded-md hover:bg-gray-100 cursor-pointer">
                <div onClick={() => setMenuVisible(prevState => !prevState)}>
                    <LoggedUser title={user} />
                </div>
                {menuMarkup}
            </div>
        </>
    )
}

interface ISelectedSchool {
    title: string,
    color?: string
}

const LoggedUser = ({ title, color = "AFECEF" }: ISelectedSchool) => {
    return (<>
        <div className="inline-flex w-full justify-center items-center gap-x-1.5">
            <span className={"text-sm"}>{title}</span>
            <img className="inline-block h-6 w-6 rounded-md ring-2 ring-white"
                src={`https://ui-avatars.com/api/?name=${title}&background=${color}`}
                alt="" />
        </div>
    </>)
}

interface ISchool {
    title: string,
    color?: string,
    onClick?: Function
}

const SchoolListItem = ({ title, color = "BCF0DA", onClick }: ISchool) => {
    return (
        <>
            <div onClick={() => onClick && onClick()}
                className="inline-flex w-full gap-x-1.5 px-4 py-2 items-center hover:bg-gray-100 cursor-pointer">
                <img className="inline-block h-6 w-6 rounded-md ring-2 ring-white"
                    src={`https://ui-avatars.com/api/?name=${title}&background=${color}`}
                    alt="" />
                <span className={"text-sm"}>{title}</span>
            </div>
        </>
    )
}

export default Menu