import { SignInForm } from "./components/SignInForm";
import { Avatar, AvatarGroup, Box, Center, Flex, HStack, Heading, SimpleGrid, Stack, Text, useBreakpointValue } from "@chakra-ui/react";

const LoginPage = () => {
    return (
        <SimpleGrid columns={{ base: 1, md: 2 }}>
            <Box className="bg-gradient-to-bl from-slate-600 to-gray-900" minH={{ base: 'auto', md: '100vh' }} display={{ base: 'none', md: 'block' }}>
                <Flex direction="column" px={{ base: '4', md: '8' }} height="full" color="on-accent">
                    <Flex align="center" h="24">
                        <HStack spacing={0} alignItems={"center"} color={"white"}>
                            {/* <Heading size={"lg"} fontWeight={"medium"}>[LOGO]</Heading> */}
                            <Heading size={"lg"} fontWeight={"light"}>Cedar Hospitality</Heading>
                        </HStack>
                    </Flex>
                    <Flex flex="1" align="center" color={'white'}>
                        <Stack spacing="8">
                            <Stack spacing="6">
                                <Heading size={useBreakpointValue({ md: 'lg', xl: 'xl' })}>
                                    Cedar Hospitality Data Cleaning
                                </Heading>
                                <Text fontSize="lg" maxW="md" fontWeight="medium">
                                Cedar Hospitality is Melbourne's leading distributor to the hospitality industry for over 30 years. Open to commercial businesses and public.
                                </Text>
                            </Stack>
                            
                        </Stack>
                    </Flex>
                    <Flex align="center" h="24">
                        <Text className="text-gray-300" fontSize="sm">
                            © 2023 DLAD Software Solution. All rights reserved.
                        </Text>
                    </Flex>
                </Flex>
            </Box>
            <Center>
                <SignInForm
                    px={{ base: '4', md: '8' }}
                    py={{ base: '12', md: '12' }}
                    width="full"
                    maxW="md"
                />
            </Center>
        </SimpleGrid>
    )
}

export default LoginPage;