import {
    Box,
    Flex,
    HStack,
    useToast
} from "@chakra-ui/react"
import { NavigateFunction, useNavigate } from "react-router-dom"
import { useLogoutMutation } from "../../../services/api.service"
import { ToastService } from "../../../services/toast.service"
import Menu from "./components/Menu"

interface INavbarProps {
    isSideBarOpen: boolean,
    closeSideBar: () => void,
    openSideBar: () => void
}

const Navbar = ({isSideBarOpen, closeSideBar, openSideBar}: INavbarProps) => {
    const navigate: NavigateFunction = useNavigate();
    const [logout, {}] = useLogoutMutation();
    const toast = new ToastService(useToast());

    const logoutHandler = async () => {
        try {
            await logout({}).unwrap();

            navigate("/", {replace: true})
            window.location.reload()

        } catch (error) {
            console.error(error);
            toast.setTitle("Error").setDescription("Unable to logout. Something went wrong").showErrorToast();
        }
    }

    return (
        <Box left={isSideBarOpen ? 64 : 0}
             className="border-b transition-all ease-in-out duration-200 bg-gray-50 fixed right-0 z-50" as="section"
             p={2} pr={{base: 'auto', sm: '4'}}>
            <Flex className="flex items-center w-full justify-between">
                <HStack spacing={0} alignItems={"center"}>
                    {/* <Box className="md:hidden">
                        <IconButton
                            onClick={isSideBarOpen ? closeSideBar : openSideBar}
                            variant="ghost"
                            icon={isSideBarOpen ? <FiX fontSize="1.25rem"/> : <FiMenu fontSize="1.25rem"/>}
                            aria-label="Open Menu"
                        />
                    </Box>
                    <Search/> */}
                </HStack>
                <HStack spacing="4">
                    {/* <ButtonGroup variant="ghost" spacing="1">
                        <IconButton icon={<FiSettings fontSize="1.25rem"/>} aria-label="Settings"/>
                        <IconButton icon={<FiHelpCircle fontSize="1.25rem"/>} aria-label="Help Center"/>
                    </ButtonGroup> */}
                    {/*<Menu>*/}
                    {/*    <MenuButton*/}
                    {/*        as={IconButton}*/}
                    {/*        className="!border-none"*/}
                    {/*        aria-label='Options'*/}
                    {/*        icon={<Avatar*/}
                    {/*            src="https://www.pngitem.com/pimgs/m/30-307416_profile-icon-png-image-free-download-searchpng-employee.png"*/}
                    {/*            className="!border !border-gray-300 !rounded-full" boxSize="10"*/}
                    {/*            name="Christoph Winston"/>}*/}
                    {/*        variant='outline'*/}
                    {/*    />*/}
                    {/*    <MenuList>*/}
                    {/*        <MenuItem icon={<FiUser/>} command='⌘T'>*/}
                    {/*            Profile*/}
                    {/*        </MenuItem>*/}
                    {/*        <MenuItem onClick={logoutHandler} icon={<FiSettings/>} command='⌘T'>*/}
                    {/*            Logout*/}
                    {/*        </MenuItem>*/}
                    {/*    </MenuList>*/}
                    {/*</Menu>*/}

                    <Menu />
                </HStack>
            </Flex>
        </Box>
    )
}

export default Navbar;