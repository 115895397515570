export const STUDENT_DATA = [
    {
        id: "STD-0001",
        first_name: "Walter",
        last_name: "O'Brien",
        birth_day: "2020-01-01",
        class: "g1",
        city: "city a",
        tags: ['Tag 1', 'Tag 2', 'Tag 3'],
        pin: "1312ad3",
        address: "591 Ridge St. Groton, CT 06340"
    },
    {
        id: "STD-0002",
        first_name: "Paige",
        last_name: "Dineen",
        birth_day: "2019-01-01",
        class: "g2",
        city: "city d",
        tags: ['Tag 2'],
        pin: "12312",
        address: "53 Tailwater Ave. Boca Raton, FL 33428"
    },
    {
        id: "STD-0003",
        first_name: "Toby",
        last_name: "Curtis",
        birth_day: "2018-01-01",
        class: "g3",
        city: "city a",
        tags: ['Tag 1'],
        pin: "xx",
        address: "23 Pawnee Drive Grandville, MI 49418"
    },
    {
        id: "STD-0004",
        first_name: "Happy",
        last_name: "Quinn",
        birth_day: "2017-01-01",
        class: "g4",
        city: "city c",
        tags: ['Tag 3'],
        pin: "1312das3",
        address: "432 Walnutwood Ave. Langhorne, PA 19047"
    },
    {
        id: "STD-0005",
        first_name: "Sylvester",
        last_name: "Dodd",
        birth_day: "2016-01-01",
        class: "g1",
        city: "city b",
        tags: ['Tag 1', 'Tag 3'],
        pin: "131ass23",
        address: "18 Valley Street Gaithersburg, MD 20877"
    }
]

export const STUDENT_LIST = [
    {
        id: "STD-0001",
        first_name: "Walter",
        last_name: "O'Brien",
        birth_day: "2020-01-01",
        class: "g1",
        gender: "male"
    },
    {
        id: "STD-0002",
        first_name: "Paige",
        last_name: "Dineen",
        birth_day: "2019-01-01",
        class: "g2",
        gender: "female"
    },
    {
        id: "STD-0003",
        first_name: "Toby",
        last_name: "Curtis",
        birth_day: "2018-01-01",
        class: "g3",
        gender: "male"
    },
    {
        id: "STD-0004",
        first_name: "Happy",
        last_name: "Quinn",
        birth_day: "2017-01-01",
        class: "g4",
        gender: "female"
    },
    {
        id: "STD-0005",
        first_name: "Sylvester",
        last_name: "Dodd",
        birth_day: "2016-01-01",
        class: "g1",
        gender: "male"
    }
]

export const EVENTS = [
    {
        id: 0,
        title: "All Day Event very long title",
        allDay: true,
        start: new Date(2023, 7, 4),
        end: new Date(2023, 7, 4)
    },
    {
        id: 1,
        title: "Long Event",
        start: new Date(2023, 7, 6),
        end: new Date(2023, 7, 6)
    }
];