import "react-big-calendar/lib/css/react-big-calendar.css";
import _ from "lodash";
import moment from "moment";
import * as Yup from "yup";
import { EVENTS } from "../../../assets/data/data";
import FormSelect from "../../common/form-controllers/FormSelect";
import ActivityTab from "../../common/activity-tab/ActivityTab";
import ResourceTab from "../../common/resource-tab/ResourceTab";
import { useFormik } from "formik";
import { useEffect } from "react";
import { FormInput } from "../../common/form-controllers/FormInput";
import { ToastService } from "../../../services/toast.service";
import { FormCheckBox } from "../../common/form-controllers/FormCheckBox";
import StationSignInModal from "../../common/station-signin-modal/StationSignInModal";
import { SCHOOL, STUDENT } from "../../../consts/methods.consts";
import StationSignOutModal from "../../common/station-signout-modal/StationSignOutModal";
import { Calendar, momentLocalizer } from "react-big-calendar";
import { InnerLoading, ProfileAvatar } from "../..";
import { FILTER_TAGS, STUDENT_TAGS } from "../../../consts/tags.consts";
import { useParams, useSearchParams } from "react-router-dom";
import { useGetQuery, usePostMutation } from "../../../services/api.service";
import PageHeader, { PageHeaderActions } from "../../common/page-header/PageHeader";
import { useObjChange, useStatusProvider } from "../../hooks";
import { FormDropdown, FormDropdownOptions } from "../../common/form-controllers/FormDropdown";
import { Tab, TabList, TabPanel, TabPanels, Tabs, useDisclosure, useToast } from "@chakra-ui/react";

moment.locale("en-GB");
const localizer = momentLocalizer(moment);

const gender: FormDropdownOptions = [
    { label: "Male", value: "Male" },
    { label: "Female", value: "Female" }
]

const ViewStudentPage = () => {
    const [searchParams] = useSearchParams();
    const toast = new ToastService(useToast());
    const signInDisclosure = useDisclosure();
    const signOutDisclosure = useDisclosure();

    const { id } = useParams()
    const [create] = usePostMutation();

    const { data: _data, isLoading } = useGetQuery({
        method: STUDENT.GET,
        body: {
            id,
            page: Number(searchParams.get("page"))
        },
        providesTags: [STUDENT_TAGS.VIEW]
    })

    const data = _.get(_data, ['message'], {})
    const studentId = _.get(data, 'student_id')
    const studentName = `${_.get(data, "first_name")} ${_.get(data, 'last_name')}`
    const profileId = _.get(data, 'profile_id')

    const formik = useFormik({
        initialValues: {},
        validationSchema: Yup.object({
            student_id: Yup.string().required("Student ID is required"),
            first_name: Yup.string().required("First name is required"),
            last_name: Yup.string().required("Last name is required"),
            class: Yup.string().required("Class is required"),
            gender: Yup.string().required("Gender is required"),
            email: Yup.string().required("Email is required"),
            birthday: Yup.string().required("Birth day is required")
        }),
        onSubmit: async (values: any) => {
            try {
                const res = await create({
                    method: STUDENT.UPDATE,
                    body: values,
                    invalidatesTags: [STUDENT_TAGS.VIEW, STUDENT_TAGS.LIST, FILTER_TAGS.STUDENT_SELECTOR]
                }).unwrap();

                toast.setTitle("Success").setDescription("Student data updated").showSuccessToast();

            } catch (error) {
                toast.setTitle("Error").setDescription("Unable to update student data. Please try again").showErrorToast();
                console.error(error);
            }
        }
    })

    const { isChanged, setInitialObj } = useObjChange(formik.values)
    const statusConfig = useStatusProvider(data);

    useEffect(() => {
        if (!_.isEmpty(data)) {
            formik.setValues(data)
            setInitialObj(data)
        }
    }, [JSON.stringify(data)])

    const actions: PageHeaderActions = [
        {
            text: "Sign in",
            onClick: () => signInDisclosure.onOpen(),
            buttonVariant: "outline",
            buttonColorScheme: "red"
        },
        {
            text: "Sign out",
            onClick: () => signOutDisclosure.onOpen(),
            buttonVariant: "outline",
            buttonColorScheme: "whatsapp"
        },
        {
            text: "Save",
            isDisabled: !isChanged,
            onClick: formik.submitForm,
            buttonVariant: "solid",
            buttonColorScheme: "messenger"
        }
    ]

    if (isLoading) {
        return (
            <>
                <PageHeader
                    isLoading={isLoading}
                    enableBackButton
                    actions={actions}
                    title={studentName}
                    subtitle={studentId} />
                <InnerLoading />
            </>
        )
    }

    return (
        <div>
            <PageHeader
                {...statusConfig}
                isLoading={isLoading}
                enableBackButton
                actions={actions}
                title={studentName}
                subtitle={studentId} />
            <StationSignInModal id={id} title={"Sign in student"} type={"student"} disclosure={signInDisclosure} />
            <StationSignOutModal id={id} title={"Sign out student"} type={"student"} disclosure={signOutDisclosure} />
            <Tabs className="mt-3">
                <TabList>
                    <Tab>Info</Tab>
                    <Tab>Activities</Tab>
                    <Tab>Resources</Tab>
                    <Tab>Calendar</Tab>
                </TabList>

                <TabPanels className="animation-form">
                    <TabPanel className="!p-0 !pt-3">
                        <div className="info-container">
                            <div className="profile-wrapper">
                                <ProfileAvatar placeholder="Student Name" doctype="Internal Profile" docname={profileId} />
                            </div>
                            <div className="form-wrapper">
                                {/* <LiveFilterInput isRequired label="Select User" data={[{"text": "Rajika"}]} accessor={"text"} isLoading={false} onSelect={console.log} /> */}
                                <FormInput isRequired label="Student Id" formik={formik} name="student_id" />
                                <FormInput isRequired label="First Name" formik={formik} name="first_name" />
                                <FormInput isRequired label="Last Name" formik={formik} name="last_name" />
                                <FormSelect method={SCHOOL.GET_CLASS_LIST_FOR_DROPDOWN} isRequired label="Class"
                                    formik={formik} name="class" />
                                <FormDropdown options={gender} isRequired label="Gender" formik={formik} name="gender" />
                                <FormInput isRequired type="date" label="Birth Day" formik={formik} name="birthday" />
                                <FormInput type="tel" label="Email" formik={formik} name="email" />
                                <FormInput type="tel" label="Mobile" formik={formik} name="mobile" />
                                <FormInput type="tel" label="Phone" formik={formik} name="phone" />
                                <FormCheckBox label="Active" formik={formik} name="active" />
                            </div>
                        </div>
                    </TabPanel>
                    <TabPanel className="!p-0 !pt-3">
                        <ActivityTab id={id} type={"student"} />
                    </TabPanel>
                    <TabPanel className="!p-0 !pt-3">
                        <ResourceTab id={id} type={"student"} />
                    </TabPanel>
                    <TabPanel className="!p-0 !pt-3">
                        <div className="!w-full !h-full">
                            <Calendar
                                views={["day", "agenda", "work_week", "month"]}
                                selectable
                                localizer={localizer}
                                defaultDate={new Date()}
                                defaultView="month"
                                events={EVENTS}
                                style={{ height: "100vh" }}
                                onSelectEvent={(event) => alert(event.title)}
                                onSelectSlot={() => {
                                }}
                            />
                        </div>
                    </TabPanel>
                </TabPanels>
            </Tabs>

            {/* <FormTester formik={formik} /> */}
        </div>
    )
}

export default ViewStudentPage