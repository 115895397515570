export enum PRODUCT {
    GET = "cedar.api.product.get",
    LIST = "cedar.api.product.get_list",
    COUNT = "cedar.api.product.count",
    COMPLETE_COUNT = "cedar.api.product.complete_count",
    INCOMPLETE_COUNT = "cedar.api.product.incomplete_count",
    APPROVED_COUNT = "cedar.api.product.approve_count",
    APPROVE = "cedar.api.product.approve",
    GET_COMPLETION = "cedar.api.product.get_completion",
    SEARCH_FILTER = "cedar.api.product.filter_product",
    GET_COMPLETED_LIST = "cedar.api.product.get_completed_list"
}

export enum STUDENT {
    LIST = "edpass.api.student.get_list",
    ADD = "edpass.api.student.add",
    GET = "edpass.api.student.get",
    UPDATE = "edpass.api.student.update",
    RESOURCE_LIST = "edpass.api.student.get_resource_list"
}

export enum STAFF {
    LIST = "edpass.api.staff.get_list",
    ADD = "edpass.api.staff.add",
    GET = "edpass.api.staff.get",
    UPDATE = "edpass.api.staff.update",
    SEARCH = "edpass.api.staff.search"
}

export enum DISCLAIMER {
    LIST = "edpass.api.disclaimer.get_list",
    ADD = "edpass.api.disclaimer.add",
    GET = "edpass.api.disclaimer.get",
    UPDATE = "edpass.api.disclaimer.update",
}

export enum PARCEL {
    LIST = "edpass.api.parcel.get_list",
    ADD = "edpass.api.parcel.add",
    GET = "edpass.api.parcel.get",
    UPDATE = "edpass.api.parcel.update",
    LIST_FOR_DROPDOWN = "edpass.api.parcel.list_for_dropdown",
}

export enum RESOURCE {
    LIST = "edpass.api.resource.get_list",
    SEARCH = "edpass.api.resource.search",
    ADD = "edpass.api.resource.add",
    UPDATE = "edpass.api.resource.update"
}

export enum DEPARTMENT {
    LIST = "edpass.api.department.get_list",
    ADD = "edpass.api.department.add",
    UPDATE = "edpass.api.department.update"
}

export enum CLASS {
    LIST = "edpass.api.grade.get_list",
    ADD = "edpass.api.grade.add",
    UPDATE = "edpass.api.grade.update"
}

export enum STATION {
    LIST = "edpass.api.station.get_list",
    ADD = "edpass.api.station.add",
    UPDATE = "edpass.api.station.update"
}

export enum RESOURCE_TYPE {
    LIST = "edpass.api.resource_type.get_list",
    ADD = "edpass.api.resource_type.add",
    UPDATE = "edpass.api.resource_type.update"
}

export enum SCHOOL {
    GET_SCHOOL_LIST = "edpass.api.school.get_list",
    GET_CLASS_LIST_FOR_DROPDOWN = "edpass.api.school.get_class_list",
    GET_STATION_LIST_FOR_DROPDOWN = "edpass.api.school.get_station_list",
    GET_CLASS_LIST_FOR_STUDENT_SELECTOR = "edpass.api.school.get_class_list_for_student_selector",
    GET_SELECTED_SCHOOL = "edpass.api.school.get_selected_school",
    GET_DEPARTMENT_LIST_FOR_DROPDOWN = "edpass.api.school.get_department_list",
    GET_RESOURCE_TYPE_LIST_FOR_DROPDOWN = "edpass.api.school.get_resource_type_list",
    SELECT_SCHOOL = "edpass.api.school.select_school",
    GET_EXTERNAL_USER_TYPES = "edpass.api.school.get_external_user_type_list",
    GET_EXTERNAL_USER_TYPES_FOR_GROUPS = "edpass.api.school.get_external_user_type_list_for_groups",
    GET_CONTRACTOR_TYPES = "edpass.api.school.get_contractor_type_list",
    GET_GUEST_TYPES = "edpass.api.school.get_guest_type_list",
    GET_DISCLAIMER_LIST_BY_USER_TYPE = 'edpass.api.school.get_disclaimer_list_by_user_type',
    GET_DISCLAIMER_LIST_BY_USER_TYPE_WITH_CONCENT = 'edpass.api.school.get_disclaimer_list_by_user_type_with_concent'
}

export enum FILTERS {
    STUDENT_SELECTOR = "edpass.api.filter.student",
    ACADEMIC_STAFF_SELECTOR = "edpass.api.filter.academic_staff",
    NON_ACADEMIC_STAFF_SELECTOR = "edpass.api.filter.non_academic_staff",
    GUARDIAN_SELECTOR = "edpass.api.filter.guardian",
    EXTERNAL_USER_SELECTOR = "edpass.api.filter.external_user",
}

export enum GUARDIAN {
    LIST = "edpass.api.guardian.get_list",
    ADD = "edpass.api.guardian.add",
    GET = "edpass.api.guardian.get",
    UPDATE = "edpass.api.guardian.update",
}

export enum THERAPIST {
    LIST = "edpass.api.therapist.get_list",
    ADD = "edpass.api.therapist.add",
    GET = "edpass.api.therapist.get",
    UPDATE = "edpass.api.therapist.update",
}

export enum CONTRACTOR {
    LIST = "edpass.api.contractor.get_list",
    ADD = "edpass.api.contractor.add",
    GET = "edpass.api.contractor.get",
    UPDATE = "edpass.api.contractor.update",
}

export enum GUEST {
    LIST = "edpass.api.guest.get_list",
    ADD = "edpass.api.guest.add",
    GET = "edpass.api.guest.get",
    UPDATE = "edpass.api.guest.update",
}

export enum GROUP {
    LIST = "edpass.api.group.get_list",
    ADD = "edpass.api.group.add",
    GET = "edpass.api.group.get",
    UPDATE = "edpass.api.group.update",
    REMOVE_MEMBER = "edpass.api.group.remove_member",
    ADD_MEMBER = "edpass.api.group.add_member",
}

export enum ACTIVITY {
    ADD = "edpass.api.activity.add",
    EVENT_LIST = "edpass.api.activity.get_event_list",
    LIST_BY_STUDENT = "edpass.api.activity.get_list_by_student",
    RESOURCE_LIST_BY_STUDENT = "edpass.api.activity.get_resource_list_by_student",
    LIST_BY_STAFF = "edpass.api.activity.get_list_by_staff",
    RESOURCE_ADD = "edpass.api.activity.resource.add",
    RESOURCE_UPDATE = "edpass.api.activity.resource.update"
}

export enum PARTICIPANT {
    UPDATE = "edpass.api.participant.update",
}

export enum ATTACHMENT {
    REMOVE_PROFILE_IMAGE = "edpass.api.attachment.remove_profile_image",
    GET_PROFILE_IMAGE = "edpass.api.attachment.get_profile_image",
}
