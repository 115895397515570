import { useColorModeValue, Stack, HStack, Text, Box, Icon, Heading, useBreakpointValue, Badge } from "@chakra-ui/react"
import { FiMoreVertical, FiArrowUpRight, FiArrowDownRight } from "react-icons/fi"

interface Props {
    label: string
    value: string
    delta?: {
        value: string
        isUpwardsTrend: boolean
    },
    className?: string
}

export const StatCard = (props: Props) => {
    const { label, value, delta, ...boxProps } = props
    return (
        <Box
            className="border border-gray-200 bg-gradient-to-bl from-green-200 to-cyan-200"
            px={{ base: '4', md: '4' }}
            py={{ base: '4', md: '4' }}
            bg="bg-surface"
            borderRadius="lg"
            boxShadow={useColorModeValue('sm', 'sm-dark')}
            {...boxProps}>
            <Stack>
                <HStack justify="space-between">
                    <Text fontSize="md" fontWeight={"semibold"} color="muted">
                        {label}
                    </Text>
                    <Icon as={FiMoreVertical} boxSize="5" color="muted" />
                </HStack>
                <HStack justify="space-between">
                    <Heading color={'gray.600'} size={useBreakpointValue({ base: 'sm', md: 'lg' })}>{value}</Heading>
                    {delta && <Badge variant="subtle" colorScheme={delta.isUpwardsTrend ? 'green' : 'red'}>
                        <HStack spacing="1">
                            <Icon as={delta.isUpwardsTrend ? FiArrowUpRight : FiArrowDownRight} />
                            <Text>{delta.value}</Text>
                        </HStack>
                    </Badge>}
                </HStack>
            </Stack>
        </Box>
    )
}