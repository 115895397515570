import {
    Button,
    HStack,
    Input,
    Stack,
    Table,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    ThemeTypings,
    ThemingProps,
    Tr,
    VStack
} from "@chakra-ui/react";
import _ from "lodash";
import search from "multi-search";
import {useEffect, useState} from 'react';
import TableTr from "./components/TableTr";
import {FaPlus} from "react-icons/fa";
import TablePagination from "./components/TablePagination";
import {usePostMutation} from "../../../services/api.service";

const EMPTY_FUNC = () => {
}

interface IColumn {
    header: string,
    width?: number,
    accessor: string | '',
    conditions?: ChakraTableColumnCondition,
    className?: string,
    conditionClassName?: (item: object) => String,
    customRenderer?: (value: string | number | null, item: object) => JSX.Element,
    disabled?: boolean,
    options?: Array<ChakraTableColumnOption>
    type?: 'link'
}

export type ChakraTableColumnOption = { label: string, onClick: (item: object) => void }

export interface ChakraTableColumnCondition {
    [key: string | number]: ThemeTypings["colorSchemes"]
}

export type ChakraTableCreatable = {
    text?: string | undefined,
    onClick: any,
    isLoading?: boolean,
    buttonColorScheme?: ThemeTypings["colorSchemes"]
}

export type ChakraTableColumns = Array<IColumn>

export interface IChakraTableProps {
    title?: string,
    columns: ChakraTableColumns,
    data: Array<object>,
    onSelected?: (line: object) => void,
    disableRowSelection?: boolean,
    outerPadding?: boolean,
    header?: boolean,
    searchable?: boolean,
    creatable?: ChakraTableCreatable,
    customFilterRenderer?: JSX.Element,
    size?: ThemingProps<"Table">["size"],
    height?: number,
    pagination?: boolean,
    pageCount?: number,
    isLoading?: boolean,
    filterMethod?: string | null
}

const SHIMMER_DATA = [1, 1, 1, 1, 1];

const ChakraTable = ({
                         isLoading,
                         size = 'md',
                         height,
                         title,
                         creatable,
                         customFilterRenderer,
                         searchable = true,
                         header = false,
                         columns,
                         data,
                         onSelected = EMPTY_FUNC,
                         disableRowSelection,
                         pagination,
                         pageCount = 1,
                         outerPadding = false,
                         filterMethod = null
                     }: IChakraTableProps) => {
    const [tableData, setTableData] = useState([])

    useEffect(() => {
        setTableData(data as any)
    }, [JSON.stringify(data)])
    const [onSearchHandler, {data: filteredData, isLoading: isFetch}] = usePostMutation()

    function selectedRow(row: any) {
        onSelected(row)
    }

    const debouncedSearch = _.debounce((searchKey) => {
        onSearchHandler({
            method: filterMethod,
            body: {key: searchKey}
        })
    }, 200)

    const searchHandler = (e: any) => {
        if (filterMethod) {
            debouncedSearch(e.target.value)
        } else {
            let filtered = search(data, e.target.value)
            setTableData(filtered as any)
        }
    }

    // if (isFetch) {
    //     isLoading = true
    // }

    const shimmerMarkup = SHIMMER_DATA.map((col: any, index: any) => <TrShimmer key={index} columns={columns}/>)

    const rowMarkup = (filteredData ? filteredData?.message : tableData || []).map((item: any, index: any) => (
        <TableTr disablePointer={disableRowSelection} onSelected={(selectedItem: any) => {
            selectedRow(selectedItem)
        }} key={index} index={index} columns={columns} item={item}/>
    ))

    return (
        <>
            <Stack border={'1px'} spacing={0} borderColor={"gray.200"} bg={"white"} borderRadius={"md"}
                   mx={outerPadding ? 3 : 0}>
                {
                    // Table Header
                    header ? (
                        <HStack mx={0} p={3} pb={5} pt={5} justifyContent={"space-between"} bg={'gray.50'}
                                rounded={'md'}>
                            {/* Table header title */}
                            {title ? <Text fontSize={size == 'sm' ? 'lg' : 'xl'}
                                           fontWeight={"semibold"}>{title}</Text> : null}
                            <HStack>
                                {/* Custom filter elements */}
                                {customFilterRenderer ? customFilterRenderer : null}
                                {searchable ?
                                    <Input opacity={isLoading ? 0 : 1} className="transition-opacity duration-100"
                                           size={size} onChange={searchHandler} width={250}
                                           placeholder={"Search Item..."} borderRadius='md' type='text'/> : null}

                                {/* Creatable */}
                                {!_.isEmpty(creatable) ? (
                                    <Button size={size} aria-label="Add new" isDisabled={creatable?.isLoading}
                                            onClick={creatable?.onClick || EMPTY_FUNC}
                                            colorScheme={creatable.buttonColorScheme || 'blue'} leftIcon={<FaPlus/>}>
                                        <HStack alignItems={"center"}>
                                            {creatable?.text ? <Text>{creatable.text}</Text> : null}
                                        </HStack>
                                    </Button>
                                ) : null}
                            </HStack>
                        </HStack>
                    ) : null
                }

                {header ? <hr></hr> : null}

                <VStack overflowY={"auto"} h={height ? height : undefined}>
                    <Table size={size} className="rounded-md" style={{marginTop: header ? '0px' : '0px !important'}}
                           variant='simple'>
                        <Thead>
                            <Tr>
                                {columns?.map((item: any, index: any) => {
                                    if (!item?.disabled) {
                                        return (
                                            <Th
                                                style={{width: item?.width ? item.width : 'unset'}}
                                                textAlign={item?.header == "Actions" ? "center" : "left"} py={2}
                                                key={index}>{item.header}
                                            </Th>
                                        )
                                    }
                                })}
                            </Tr>
                        </Thead>
                        <Tbody>
                            {(isLoading || isFetch) && shimmerMarkup}
                            {(!isLoading && !isFetch) && rowMarkup}
                        </Tbody>
                    </Table>
                </VStack>
                {(!isLoading && _.isEmpty(tableData)) ?
                    <Text className="animation-no-data" pb={3} w={'full'} fontWeight={'semibold'} textAlign={'center'}>No
                        Data</Text> : null}

                {pagination && <TablePagination isLoading={isLoading} pageCount={pageCount}/>}
            </Stack>
        </>
    )
}

const TrShimmer = ({columns = []}: any) => {
    return (
        <Tr>
            {columns.map((col: any, index: any) => <Td key={index}>
                <div className="animate-pulse w-[75px] h-[15px] bg-gray-100 rounded-md border"></div>
            </Td>)}
        </Tr>
    )
}

export default ChakraTable
