import { FormControl, FormErrorMessage, FormLabel, Input, InputProps } from "@chakra-ui/react";
import _ from "lodash";

type MutatedTypes = Omit<InputProps, 'onChange' | 'name' | 'isRequired' | 'isInvalid' | 'defaultValue'>

export interface FormInputProps extends MutatedTypes {
    containerClassNames?: string,
    formik: any,
    name: string,
    label?: string,
    isRequired?: boolean,
    customMessage?: any,
}

export const FormInput = ({ rounded = "md", size = 'sm', customMessage, formik, isRequired = false, name, label, containerClassNames = '', ...restProps }: FormInputProps) => {

    return (
        <FormControl isRequired={isRequired} className={containerClassNames} isInvalid={Boolean(formik.submitCount && formik.errors[name])}>
            {label && <FormLabel fontSize={14} color={"gray.900"}>{label}</FormLabel>}
            <Input _ shadow={"sm"} borderColor={'gray.300'} size={size} {...restProps} name={name} value={_.isEmpty(formik.values) ? '' : formik.values[name]} onChange={formik.handleChange} rounded={rounded} />
            {(formik.submitCount && formik.errors[name]) ? <FormErrorMessage mt={0}>{formik.errors[name]}</FormErrorMessage> : customMessage && customMessage}
        </FormControl>
    )
}