import _ from "lodash";
import Navbar from "../../common/navbar/Navbar";
import { SideBar } from "../../common/sidebar/SideBar";
import { setUser } from "../../../store/reducers/common/common-store";
import { useEffect } from "react";
import { useSideBar } from "../../hooks";
import { useDispatch } from "react-redux";
import { Box, Spinner } from "@chakra-ui/react";
import { useIsLoginQuery } from "../../../services/api.service";
import { NavigateFunction, Outlet, useNavigate } from "react-router-dom";

const InternalLayout = () => {
    const navigate: NavigateFunction = useNavigate()
    const dispatch = useDispatch()

    const { isSideBarOpen, openSideBar, closeSideBar } = useSideBar();
    const { data, isFetching, status = 'Pending' } = useIsLoginQuery({})

    const user = _.get(data, ["message", "user"], "Guest")
    const isAuthenticated = !isFetching && status === 'fulfilled'

    useEffect(() => {
        if (isAuthenticated) {
            if (user && user !== 'Guest') {
                dispatch(setUser(user))
            } else {
                navigate("/", { replace: true })
                window.location.reload()
            }
        }
    }, [user, status])

    if (isAuthenticated && user !== 'Guest') {
        return (
            <>
                <Box w={isSideBarOpen ? 64 : 0} as="aside" id="cta-button-sidebar" className="fixed box-border top-0 left-0 z-40 h-screen transition-all duration-300" aria-label="Sidebar">
                    <div className="h-full border-r py-2 overflow-y-auto bg-gradient-to-bl from-slate-100 shadow-sm to-slate-200">
                        <SideBar />
                    </div>
                </Box>

                <Box ml={isSideBarOpen ? 64 : 0} className="transition-all duration-300">
                    <Navbar isSideBarOpen={isSideBarOpen} closeSideBar={closeSideBar} openSideBar={openSideBar} />
                    <div className="p-4 pt-[74px] relative">
                        <Outlet />
                    </div>
                </Box>
            </>
        )
    }

    return (
        <div className="h-[100vh] w-full flex justify-center items-center">
            <Spinner size={"md"} />
        </div>
    )
}

export default InternalLayout;