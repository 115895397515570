import { configureStore } from '@reduxjs/toolkit'
import rootReducer from "./reducers/root-reducer";
import apiService from "../services/api.service";

const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware: any) => {
        // getDefaultMiddleware().concat(usersApi.middleware);
        return getDefaultMiddleware({
            serializableCheck: false
        })
            .concat(apiService.middleware)
    },
})

export default store