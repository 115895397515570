import {SimpleGrid} from "@chakra-ui/react";
import ChakraTable, {ChakraTableColumns} from "../../common/chakra-table/ChakraTable";
import {useGetQuery} from "../../../services/api.service";
import {NavigateFunction, useNavigate, useSearchParams} from "react-router-dom";
import {useTableDataProvider} from "../../hooks";
import {PRODUCT} from "../../../consts/methods.consts";
import {PRODUCT_TAG} from "../../../consts/tags.consts";
import CompleteProductStatCard from "../home/components/CompleteProductStatCard";
import ProductStatCard from "../home/components/ProductStatCard";
import IncompleteProductStatCard from "../home/components/IncompleteProductStatCard";

export const PRODUCT_LIST_COLUMNS: ChakraTableColumns = [
    {
        header: "Product Code",
        accessor: "product_code"
    },
    {
        header: "Product Name",
        accessor: "product_name",
    },
    {
        header: "Brand",
        accessor: "brand",
    },
    {
        header: "Supplier Code",
        accessor: "supplier_code",
    },
    {
        header: "Is Active",
        accessor: "is_active",
        conditions: {
            "Yes": "green",
            "No": "red"
        }
    }
]

const CompletedList = () => {
    const navigate: NavigateFunction = useNavigate()
    const [searchParams] = useSearchParams()

    const {data, isLoading} = useGetQuery({
        method: PRODUCT.GET_COMPLETED_LIST,
        body: {
            page: Number(searchParams.get("page"))
        },
        providesTags: [PRODUCT_TAG.LIST]
    })

    const {dataList, pageCount} = useTableDataProvider(data);

    const tableLineClickHandler = (line: any) => {
        navigate(`product/${line.name}`)
    }

    return (
        <div>
            <SimpleGrid columns={{base: 1, md: 3}} gap={{base: '4', md: '4'}}>
                <ProductStatCard/>
                <CompleteProductStatCard/>
                <IncompleteProductStatCard/>
            </SimpleGrid>

            <div className="mt-4 pt-4">
                <ChakraTable pageCount={pageCount} isLoading={isLoading} pagination size={"sm"} header title="Products"
                             onSelected={tableLineClickHandler} columns={PRODUCT_LIST_COLUMNS} data={dataList}
                             filterMethod={PRODUCT.SEARCH_FILTER}
                />
            </div>
        </div>

    )
}

export default CompletedList;
