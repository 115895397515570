import "./styles/global.css";

import store from "./store/store";
import { Provider } from "react-redux";
import RouterConfigs from "./routes/route-configs";
import { BrowserRouter } from "react-router-dom";
import { ChakraProvider, defineStyleConfig, extendTheme } from "@chakra-ui/react";

const breakpoints = {
    sm: '640px',
    md: '768px',
    lg: '1024px',
    xl: '1280px',
    '2xl': '1536px',
}

const Tabs = defineStyleConfig({
    defaultProps: {
        colorScheme: 'teal'
    }
})

const theme = extendTheme({
    breakpoints,
    components: {
        Tabs
    }
});

export const App = () => {
    return (
        <Provider store={store}>
            <ChakraProvider theme={theme}>
                <BrowserRouter>
                    <RouterConfigs />
                </BrowserRouter>
            </ChakraProvider>
        </Provider>
    )
}
