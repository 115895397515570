import * as Yup from "yup";
import _ from "lodash";
import moment from "moment";
import { STAFF } from "../../../consts/methods.consts";
import { useFormik } from "formik";
import { useEffect } from "react";
import ActivityTab from "../../common/activity-tab/ActivityTab";
import { useParams } from "react-router-dom";
import { FormInput } from "../../common/form-controllers/FormInput";
import { ToastService } from "../../../services/toast.service";
import { FormCheckBox } from "../../common/form-controllers/FormCheckBox";
import StationSignInModal from "../../common/station-signin-modal/StationSignInModal";
import StationSignOutModal from "../../common/station-signout-modal/StationSignOutModal";
import { PageHeaderActions } from "../../common/page-header/PageHeader";
import { EVENTS, STUDENT_LIST } from "../../../assets/data/data";
import { FILTER_TAGS, STAFF_TAGS } from "../../../consts/tags.consts";
import { STUDENT_LIST_TAB_COLUMNS } from "../student/StudentListPage";
import { Calendar, momentLocalizer } from "react-big-calendar";
import { useGetQuery, usePostMutation } from "../../../services/api.service";
import { useObjChange, useStatusProvider } from "../../hooks";
import { FormDropdown, FormDropdownOptions } from "../../common/form-controllers/FormDropdown";
import { ChakraTable, InnerLoading, PageHeader, ProfileAvatar } from "../..";
import { Tab, TabList, TabPanel, TabPanels, Tabs, useDisclosure, useToast } from "@chakra-ui/react";

moment.locale("en-GB");
const localizer = momentLocalizer(moment);

const STAFF_TYPE: FormDropdownOptions = [
    {
        label: "Academic",
        value: "ACADEMIC"
    },
    {
        label: "Non Academic",
        value: "NON_ACADEMIC"
    }
]

const ViewStaffPage = () => {
    const { id } = useParams()
    const [create] = usePostMutation();
    const toast = new ToastService(useToast());

    const signInDisclosure = useDisclosure();
    const signOutDisclosure = useDisclosure();

    const { data: _data, isLoading, status } = useGetQuery({
        method: STAFF.GET,
        body: { id },
        providesTags: [STAFF_TAGS.VIEW]
    })

    const data = _.get(_data, ['message'], {})
    const staffId = _.get(data, 'staff_id')
    const staffName = `${_.get(data, "first_name")} ${_.get(data, 'last_name')}`
    const profileId = _.get(data, 'profile_id')

    const formik = useFormik({
        initialValues: {},
        validationSchema: Yup.object({
            staff_id: Yup.string().required("Staff ID is required"),
            first_name: Yup.string().required("First name is required"),
            last_name: Yup.string().required("Last name is required"),
            type: Yup.string().required("Staff type is required"),
            email: Yup.string().required("Email is required"),
            mobile: Yup.string().required("Mobile is required")
        }),
        onSubmit: async (values: any) => {
            try {
                const res = await create({
                    method: STAFF.UPDATE,
                    body: values,
                    invalidatesTags: [STAFF_TAGS.VIEW, STAFF_TAGS.LIST, FILTER_TAGS.STAFF_SELECTOR]
                }).unwrap();

                toast.setTitle("Success").setDescription("Staff data updated").showSuccessToast();

            } catch (error) {
                toast.setTitle("Error").setDescription("Unable to update Staff data. Please try again").showErrorToast();
                console.error(error);
            }
        }
    })

    const { isChanged, setInitialObj } = useObjChange(formik.values)
    const statusConfig = useStatusProvider(data);

    useEffect(() => {
        if (!_.isEmpty(data)) {
            formik.setValues(data)
            setInitialObj(data)
        }
    }, [JSON.stringify(data)])

    const actions: PageHeaderActions = [
        {
            text: "Sign in",
            onClick: () => signInDisclosure.onOpen(),
            buttonVariant: "outline",
            buttonColorScheme: "red"
        },
        {
            text: "Sign out",
            onClick: () => signOutDisclosure.onOpen(),
            buttonVariant: "outline",
            buttonColorScheme: "whatsapp"
        },
        {
            text: "Save",
            isDisabled: !isChanged,
            onClick: formik.submitForm,
            buttonVariant: "solid",
            buttonColorScheme: "teal"
        }
    ]

    if (isLoading) {
        return (
            <>
                <PageHeader isLoading={isLoading} enableBackButton actions={actions} title={staffName} subtitle={staffId} />
                <InnerLoading />
            </>
        )
    }

    return (
        <>
            <PageHeader {...statusConfig} isLoading={isLoading} enableBackButton actions={actions} title={staffName} subtitle={staffId} />
            <StationSignInModal id={id} title={"Sign in staff"} type={"staff"} disclosure={signInDisclosure} />
            <StationSignOutModal id={id} title={"Sign out staff"} type={"staff"} disclosure={signOutDisclosure} />
            <Tabs className="mt-3">
                <TabList>
                    <Tab>Info</Tab>
                    <Tab>Activities</Tab>
                    <Tab>Resources</Tab>
                    <Tab>Calendar</Tab>
                </TabList>

                <TabPanels className="animation-form">
                    <TabPanel className="!p-0 !pt-3">
                        <div className="info-container">
                            <div className="profile-wrapper">
                                <ProfileAvatar placeholder="Staff Name" doctype="Internal Profile" docname={profileId} />
                            </div>
                            <div className="form-wrapper">
                                <FormInput isRequired label="Staff Id" formik={formik} name="staff_id" />
                                <FormInput isRequired label="First Name" formik={formik} name="first_name" />
                                <FormInput isRequired label="Last Name" formik={formik} name="last_name" />
                                <FormDropdown options={STAFF_TYPE} isRequired label="Type" formik={formik} name="type" />
                                <FormInput isRequired type="email" label="Email" formik={formik} name="email" />
                                <FormInput isRequired type="tel" label="Mobile" formik={formik} name="mobile" />
                                <FormInput type="tel" label="Phone" formik={formik} name="phone" />
                                <FormCheckBox label="Active" formik={formik} name="active" />
                            </div>
                        </div>
                    </TabPanel>
                    <TabPanel className="!p-0 !pt-3">
                        <ActivityTab id={id} type={"staff"} />
                    </TabPanel>
                    <TabPanel className="!p-0 !pt-3">
                        <ChakraTable size={"sm"} header title="Resources" columns={STUDENT_LIST_TAB_COLUMNS} data={STUDENT_LIST} />
                    </TabPanel>
                    <TabPanel className="!p-0 !pt-3">
                        <div className="!w-full !h-full">
                            <Calendar
                                views={["day", "agenda", "work_week", "month"]}
                                selectable
                                localizer={localizer}
                                defaultDate={new Date()}
                                defaultView="month"
                                events={EVENTS}
                                style={{ height: "100vh" }}
                                onSelectEvent={(event) => alert(event.title)}
                                onSelectSlot={() => { }}
                            />
                        </div>
                    </TabPanel>
                </TabPanels>
            </Tabs>

            {/* <FormTester formik={formik} /> */}
        </>
    )
}

export default ViewStaffPage