import { PRODUCT } from "../../../../consts/methods.consts"
import { PRODUCT_TAG } from "../../../../consts/tags.consts"
import { useGetQuery } from "../../../../services/api.service"
import { StatCard } from "../../../common/stat/StatCard"

const ProductStatCard = () => {

    const { data } = useGetQuery({
        method: PRODUCT.COUNT,
        body: {},
        providesTags: [PRODUCT_TAG.LIST]
    })

    return (
        <StatCard key={'product_count'} label='Products' value={String(data?.message ?? 0)} className={"bg-gradient-to-bl from-purple-100 to-purple-200"} />
    )
}

export default ProductStatCard