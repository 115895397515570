import { combineReducers } from "redux";

import _common from '../reducers/common/common-store';
import apiService from "../../services/api.service";

const rootReducer = combineReducers({
    common: _common,
    [apiService.reducerPath]: apiService.reducer,
});

export default rootReducer;