import SignInOut from "./components/SignInOut"
import { useState } from "react"
import { ACTIVITY } from "../../../consts/methods.consts"
import { ChakraTable } from "../.."
import { useGetQuery } from "../../../services/api.service"
import { ACTIVITY_TAGS } from "../../../consts/tags.consts"
import { useDisclosure } from "@chakra-ui/react"
import { ChakraTableColumns } from "../chakra-table/ChakraTable"
import { useTableDataProvider } from "../../hooks"

interface IActivityTab {
    id: string | undefined,
    type: "student" | "staff"
}

const methodMap: any = {
    "student": ACTIVITY.LIST_BY_STUDENT,
    "staff": ACTIVITY.LIST_BY_STAFF
}


const LIST_TAB_COLUMNS: ChakraTableColumns = [
    {
        accessor: "description",
        header: "Description"
    },
    {
        accessor: "sign_in_station",
        header: "Sign In Station"
    },
    {
        accessor: "sign_in",
        header: "Sign In",
        conditions: {
            'No': 'red',
            'Yes': 'green'
        }
    },
    {
        accessor: "sign_in_time",
        header: "Sign In Time"
    },
    {
        accessor: "sign_out_station",
        header: "Sign Out Station"
    },
    {
        accessor: "sign_out",
        header: "Sign Out",
        conditions: {
            'No': 'red',
            'Yes': 'green'
        }
    },
    {
        accessor: "sign_out_time",
        header: "Sign Out Time"
    }
]

const ActivityTab = ({ id, type }: IActivityTab) => {
    const [activity, setActivity] = useState(null)
    const _disclosure = useDisclosure();

    const { data, isLoading } = useGetQuery({
        method: methodMap[type],
        body: { id },
        providesTags: [ACTIVITY_TAGS.LIST]
    })

    const { pageCount, dataList } = useTableDataProvider(data)

    const _onSelected = (line: any) => {
        setActivity(line)
        _disclosure.onOpen()
    }

    return (
        <>
            <SignInOut disclosure={_disclosure} data={activity} />
            <ChakraTable
                size={"sm"}
                header title="Activities"
                onSelected={_onSelected}
                isLoading={isLoading}
                columns={LIST_TAB_COLUMNS}
                pageCount={pageCount}
                pagination
                data={dataList} />
        </>
    )
}

export default ActivityTab