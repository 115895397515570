import { Spinner } from "@chakra-ui/react"

const InnerLoading = () => {
    return (
        <div className="h-[20vh] w-full flex justify-center items-center">
            <Spinner size={"md"} />
        </div>
    )
}

export default InnerLoading