import { Progress, Text } from "@chakra-ui/react"
import _ from "lodash"
import { PRODUCT } from "../../../consts/methods.consts"
import { PRODUCT_TAG } from "../../../consts/tags.consts"
import { useGetQuery } from "../../../services/api.service"


export const ProgressSection = ({ id }: any) => {

    const { data: _data, isLoading } = useGetQuery({
        method: PRODUCT.GET_COMPLETION,
        body: {
            id
        },
        providesTags: [PRODUCT_TAG.VIEW]
    })

    const data = _.get(_data, ['message'], [])

    return (
        <>
            <Text fontSize='sm'>Percentage: {data}%</Text>
            <Progress value={data} colorScheme={data < 100 ? 'red' : 'green'} borderRadius={'md'} />
        </>
    )
}