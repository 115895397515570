import {useRoutes} from "react-router-dom";
import {ExternalLayout, InternalLayout} from "../components/layouts";
import {HomePage, LoginPage} from "../components/pages";
import ViewProductPage from "../components/pages/product/ViewProductPage";
import CompletedList from "../components/pages/completedList/CompletedList";

const RouterConfigs = () => {
    const element = useRoutes([
        {
            path: "/",
            element: <ExternalLayout/>,
            children: [
                {
                    index: true,
                    element: <LoginPage/>
                },
            ]
        },
        {
            path: "/app",
            element: <InternalLayout/>,
            children: [
                {index: true, element: <HomePage/>},
                {path: 'product/:id', element: <ViewProductPage/>},
                {path: 'completed-list', element: <CompletedList/>},
            ],
        },
        {path: "*", element: <h1>page not found</h1>},
    ]);

    return element;
};

export default RouterConfigs;
