import _ from "lodash"

type UseTableDataProviderR = {
    dataList: Array<any>,
    pageCount?: number
}

type Options = {
    withoutPagination: boolean
}

export const useTableDataProvider = (response: Object, options: Options | undefined = undefined): UseTableDataProviderR => {

    if (options?.withoutPagination) {
        return {
            dataList: _.get(response, ["message"], [])
        }
    }

    return {
        dataList: _.get(response, ["message", "data"], []),
        pageCount: _.get(response, ["message", "page_count"], 0)
    }
}