import { IconButton } from "@chakra-ui/react"
import { FiArrowLeft, FiArrowRight } from "react-icons/fi"
import { useSearchParams } from "react-router-dom"
import { URLSearchParams } from "url";

interface TablePaginationProps {
    pageCount: number,
    isLoading?: boolean
}

const TablePagination = ({ pageCount, isLoading }: TablePaginationProps) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const currentPage = Number(searchParams.get("page") || 1);

    const onNext = () => setSearchParams((params: URLSearchParams) => {
        const page = (Number(params.get("page") || 1) + 1).toString();
        params.set("page", page);
        return params;
    });

    const onPrevious = () => {
        setSearchParams((params: URLSearchParams) => {
            const page = (Number(params.get("page") || 1) - 1).toString()

            if (page == "1") {
                params.set("page", page);
                return params;
            }

            params.set("page", page);
            return params;
        })
    }

    const styles = {
        opacity: isLoading ? 0 : 1,
        height: isLoading ? 0 : 52
    }

    return (
        <div style={{ ...styles }} className="w-full px-4 transition-all duration-500 flex items-center gap-6 justify-end bg-gray-50">
            <p className="text-sm">{`Showing ${currentPage} of ${pageCount} page${pageCount > 1 ? 's' : ''}`}</p>

            <div className="flex items-center gap-3 lg:gap-2">
                <IconButton
                    onClick={onPrevious}
                    size={"sm"}
                    isDisabled={currentPage === 1}
                    variant="outline"
                    icon={<FiArrowLeft fontSize="1rem" />}
                    aria-label="Previous"
                />
                <IconButton
                    size={"sm"}
                    onClick={onNext}
                    isDisabled={currentPage >= pageCount}
                    variant="outline"
                    icon={<FiArrowRight fontSize="1rem" />}
                    aria-label="Next"
                />
            </div>
        </div>
    )
}

export default TablePagination;