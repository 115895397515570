import { Badge, Button, FormControl, FormLabel, Progress, Tab, TabList, TabPanel, TabPanels, Tabs, useDisclosure, useToast } from "@chakra-ui/react";
import { useFormik } from "formik";
import _ from "lodash";
import moment from "moment";
import { useEffect } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import * as Yup from "yup";
import { ChakraTable, InnerLoading } from "../..";
import { FILE_PATH } from "../../../consts/app.consts";
import { PRODUCT } from "../../../consts/methods.consts";
import { PRODUCT_TAG } from "../../../consts/tags.consts";
import { useGetQuery, usePostMutation } from "../../../services/api.service";
import { ToastService } from "../../../services/toast.service";
import { ChakraTableColumns } from "../../common/chakra-table/ChakraTable";
import { FormInput } from "../../common/form-controllers/FormInput";
import { FormTextArea } from "../../common/form-controllers/FormTextArea";
import PageHeader, { PageHeaderActions } from "../../common/page-header/PageHeader";
import { ProgressSection } from "./ProgressSection";

moment.locale("en-GB");

export const ATTACHMENT_LIST_COLUMNS: ChakraTableColumns = [
    {
        header: "Description",
        accessor: "description"
    },
    {
        header: "File",
        accessor: "attachment",
        customRenderer(value, item) {
            const _onClick = () => {
                window.open(`${FILE_PATH}${value}`, "blank")
            }
            return (
                <Button onClick={_onClick} colorScheme='blue' variant='link'>
                    {value}
                </Button>
            )
        },
    }
]

const ViewProductPage = () => {
    const [searchParams] = useSearchParams();
    const toast = new ToastService(useToast());
    const signInDisclosure = useDisclosure();
    const signOutDisclosure = useDisclosure();

    const { id } = useParams()
    const [create] = usePostMutation();

    const { data: _data, isLoading } = useGetQuery({
        method: PRODUCT.GET,
        body: {
            id,
            page: Number(searchParams.get("page"))
        },
        providesTags: [PRODUCT_TAG.VIEW]
    })

    const data = _.get(_data, ['message'], {})
    const product_code = _.get(_data, ['message', 'product_code'], "")
    const product_name = _.get(_data, ['message', 'product_name'], "")
    const brand_image = _.get(_data, ['message', 'brand_image'], null)
    const image_1 = _.get(_data, ['message', 'image_1'], null)
    const image_2 = _.get(_data, ['message', 'image_2'], null)
    const image_3 = _.get(_data, ['message', 'image_3'], null)
    const image_4 = _.get(_data, ['message', 'image_4'], null)
    const attachments = _.get(_data, ['message', 'attachments'], [])
    const is_active = _.get(_data, ['message', 'is_active'], 0)
    const status = _.get(_data, ['message', 'status'], null)
    const is_approved = _.get(_data, ['message', 'is_approved'], 0)

    useEffect(() => {
        if (!_.isEmpty(data)) {
            formik.setValues(data)
        }
    }, [JSON.stringify(data)])

    const formik = useFormik({
        initialValues: {},
        validationSchema: Yup.object({}),
        onSubmit: async (values: any) => {
            try {

            } catch (error) {
                toast.setTitle("Error").setDescription("Unable to update student data. Please try again").showErrorToast();
                console.error(error);
            }
        }
    })

    const tableLineClickHandler = (line: any) => {

    }

    const markAsApprovedHandler = async () => {
        try {
            await create({
                method: PRODUCT.APPROVE,
                body: { id },
                invalidatesTags: [PRODUCT_TAG.LIST, PRODUCT_TAG.VIEW]
            }).unwrap();

            toast.setTitle("Success").setDescription("Mark As Approved").showSuccessToast();
        } catch (error) {
            toast.setTitle("Error").setDescription("Unable to update. Please try again").showErrorToast();
            console.error(error);
        }
    }

    const actions: PageHeaderActions = [
        {
            text: "Mark as Approved",
            isDisabled: (is_approved === 1),
            onClick: markAsApprovedHandler,
            buttonVariant: "outline",
            buttonColorScheme: "teal"
        }
    ]

    if (isLoading) {
        return (
            <>
                <PageHeader
                    isLoading={isLoading}
                    enableBackButton
                    actions={actions}
                    title={product_name}
                    subtitle={product_code} />
                <InnerLoading />
            </>
        )
    }

    const subtitleMarkup = (
        <div className="flex gap-2">
            {is_active === 1 ? <Badge colorScheme='green'>Active</Badge> : <Badge colorScheme='red'>InActive</Badge>}
            {status === "Completed" ? <Badge colorScheme='green'>Completed</Badge> : <Badge colorScheme='red'>Not Completed</Badge>}
            {is_approved === 1 ? <Badge colorScheme='green'>Approved</Badge> : <Badge colorScheme='red'>Not Approved</Badge>}
        </div>
    )

    return (
        <div>
            <PageHeader
                subTitleRenderer={subtitleMarkup}
                isLoading={isLoading}
                enableBackButton
                actions={actions}
                title={product_name}
                subtitle={product_code}
            />
            <ProgressSection id={product_code}/>
            <Tabs className="mt-3">
                <TabList>
                    <Tab>Information</Tab>
                    <Tab>Images</Tab>
                    <Tab>Attachments</Tab>
                </TabList>

                <TabPanels className="animation-form">
                    <TabPanel className="!p-0 !pt-3">
                        <div className="border-b pb-8">
                            <div className="mt-8 info-container">
                                <FormInput isReadOnly label="Product Code" formik={formik} name="product_code" />
                                <FormInput isReadOnly label="Presta ID" formik={formik} name="id_product" />
                                <FormInput isReadOnly label="Supplier Code" formik={formik} name="supplier_code" />
                                <FormInput isReadOnly label="Categories" formik={formik} name="categories" />
                            </div>
                        </div>
                        <div className="border-b pb-8">
                            <div className="mt-8 two-col-container">
                                <FormInput isReadOnly label="Jiwa Product Description" formik={formik} name="product_name" />
                                <FormInput isReadOnly label="Jiwa E-Com Description" formik={formik} name="ecom_name" />
                            </div>
                        </div>
                        <div className="border-b pb-8">
                            <div className="mt-8 two-col-container">
                                <FormTextArea rows={10} isReadOnly label="Description" formik={formik} name="description" />
                            </div>
                        </div>
                        <div className="border-b pb-8">
                            <div className="mt-8 info-container">
                                <FormInput isReadOnly label="Category 1" formik={formik} name="category1" />
                                <FormInput isReadOnly label="Category 2" formik={formik} name="category2" />
                                <FormInput isReadOnly label="Category 3 - Brand" formik={formik} name="category3" />
                                <FormInput isReadOnly label="Category 4 - Range" formik={formik} name="category4" />
                                <FormInput isReadOnly label="Category 5" formik={formik} name="category5" />
                            </div>
                        </div>
                        <div className="border-b py-8">
                            <h2 className="text-base font-semibold leading-7 text-gray-900">Brand Details</h2>
                            <div className=" mt-8 info-container">
                                <div className="flex flex-col gap-4">
                                    <FormInput isReadOnly label="Brand Name" formik={formik} name="brand_name" />
                                    <FormControl>
                                        <FormLabel fontSize={14} color={"gray.900"}>Brand Logo</FormLabel>
                                        <img src={brand_image ? `${FILE_PATH}${brand_image}` : "https://via.placeholder.com/150"} className="h-36 w-36 object-cover object-center rounded-lg" />
                                    </FormControl>
                                </div>
                                <FormInput isReadOnly label="Brand Code" formik={formik} name="brand_code" />

                                <div className="w-full h-full flex justify-start items-end">
                                </div>
                            </div>
                        </div>
                        <div className="border-b py-8">
                            <h2 className="text-base font-semibold leading-7 text-gray-900">Features</h2>
                            <div className=" mt-8 info-container">
                                <FormInput isReadOnly label="Color" formik={formik} name="color" />
                                <FormInput isReadOnly label="Material" formik={formik} name="material" />
                                <FormInput isReadOnly label="Range" formik={formik} name="range" />
                            </div>
                        </div>
                        <div className="border-b py-8">
                            <h2 className="text-base font-semibold leading-7 text-gray-900">Dimensions</h2>
                            <div className=" mt-8 info-container">
                                <FormInput isReadOnly label="Width" formik={formik} name="width" />
                                <FormInput isReadOnly label="Height" formik={formik} name="height" />
                                <FormInput isReadOnly label="Weight" formik={formik} name="weight" />
                                <FormInput isReadOnly label="Depth" formik={formik} name="depth" />
                            </div>
                        </div>
                        <div className="border-b py-8">
                            <h2 className="text-base font-semibold leading-7 text-gray-900">Unit Of Measurements</h2>
                            <div className=" mt-8 info-container">
                                <FormInput isReadOnly label="Selling UOM" formik={formik} name="selling_uom" />
                                <FormInput isReadOnly label="Inventory UOM" formik={formik} name="inventory_uom" />
                            </div>
                        </div>
                        <div className="border-b py-8">
                            <h2 className="text-base font-semibold leading-7 text-gray-900">Product Summary</h2>
                            <div className=" mt-8 one-col-container">
                                <FormControl>
                                    <div dangerouslySetInnerHTML={{ __html: data?.product_summary }} />
                                </FormControl>
                            </div>
                        </div>

                    </TabPanel>
                    <TabPanel className="!p-0 !pt-3">
                        <div className="flex gap-2">

                        </div>
                        <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-2">
                            <img className="rounded-lg aspect-square" src={image_1 ? `${FILE_PATH}${image_1}` : "https://via.placeholder.com/450"} alt="" />
                            <img className="rounded-lg aspect-square" src={image_2 ? `${FILE_PATH}${image_2}` : "https://via.placeholder.com/450"} alt="" />
                            <img className="rounded-lg aspect-square" src={image_3 ? `${FILE_PATH}${image_3}` : "https://via.placeholder.com/450"} alt="" />
                            <img className="rounded-lg aspect-square" src={image_4 ? `${FILE_PATH}${image_4}` : "https://via.placeholder.com/450"} alt="" />
                        </div>
                    </TabPanel>
                    <TabPanel className="!p-0 !pt-3">
                        <ChakraTable size={"sm"} header title="Attachments" onSelected={tableLineClickHandler} columns={ATTACHMENT_LIST_COLUMNS} data={attachments} />
                    </TabPanel>
                </TabPanels>
            </Tabs>

            {/* <FormTester formik={formik} /> */}
        </div>
    )
}

export default ViewProductPage